import GenericModel from "@/shared/models/generic-model";
import i18n from "@/shared/plugins/vue-i18n";
import StringField from "@/shared/fields/string-field";
import IdField from "@/shared/fields/id-field";
import { InstitutionsField } from "@/shared/common-fields/institutions-field";
import BooleanField from "@/shared/fields/boolean-field";

const label = (name) =>
    i18n.t(`RELATED_APPLICATION.FIELDS.${name.toUpperCase()}`);

const fields = {
    id: new IdField("id", "Id"),
    allocated_document_series: new StringField(
        "allocated_document_series",
        label("allocated_document_series"),
        { required: true, max: 16 }
    ),
    issued_date: new StringField("issued_date", label("issued_date"), {
        required: true,
        stringLength: {
            min: 4,
            max: 4,
        },
    }),
    issued_by: new InstitutionsField.relationToOne("issued_by", label("issued_by"), {
        autocompleteRequired: true,
    }),
    related_document: new StringField(
        "related_document",
        label("related_document"),
        { required: true }
    ),
    is_external: new BooleanField(
        'is_external',
        label('is_external'),
        { required: false },
    ),
};

export class RelatedApplicationModel extends GenericModel {
    static get fields() {
        return fields;
    }
}

