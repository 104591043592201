import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { VillageField } from '@/shared/common-fields/village-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { CountyField } from '@/shared/common-fields/county-field';
import NumberField from '@/shared/fields/number-field';

const label = (name) => i18n.t(`LOCALITIES.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`LOCALITIES.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`LOCALITIES.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);


const fields = {
    id: new IdField('id', 'Id'),
    county: new StringField('county', label('county')),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true },
        { placeholder: placeholder('county')},
        [],
        { asyncData: false }
    ),
    territorial_administrative_unit: new StringField('territorial_administrative_unit', label('territorial_administrative_unit')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: false }
    ),
    village_id: VillageField.relationToOne(
        'village_id',
        label('village'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true },
        { placeholder: placeholder('village')},
        [{
            key: 'parent_id',
            name: 'territorial_administrative_unit_id'
        }],
        { asyncData: false }
    ),
    village: new StringField('village', label('village')),
    street: new StringField('street', label('street'), { required: false }),
    street_number: new StringField('street_number', label('street_number_short'), { required: true }),
    block: new StringField('block', label('block')),
    staircase: new StringField('staircase', label('staircase')),
    floor: new StringField('floor', label('floor')),
    apartment: new StringField('apartment', label('apartment')),
};

export class LocalityModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
