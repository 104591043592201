<template>
  <div class="form-config-container">
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>
