import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { CommonService } from '@/shared/services/common.service';

export class VillageField {
    static relationToOne(name, label, rules, filterParams, children, options, localityOptions) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                if(localityOptions?.includeMunicipality) {
                    return CommonService.getVillages({...params, return_parent_city: true});
                } else {
                    return CommonService.getVillages(params);
                }
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
