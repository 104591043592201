var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-form-container"},[(_vm.data.list.length == 0)?_c('div',{staticClass:"form-empty"},[_vm._v(" "+_vm._s(_vm.$t("FORMS.DESCRIPTION.CONTAINER_EMPTY"))+" ")]):_vm._e(),_c('b-form',{attrs:{"size":_vm.data.config.size,"label-suffix":":","label-position":_vm.data.config.labelPosition,"label-width":_vm.data.config.labelWidth + 'px'}},[_c('draggable',_vm._b({on:{"end":_vm.handleMoveEnd,"add":_vm.handleWidgetAdd},model:{value:(_vm.data.list),callback:function ($$v) {_vm.$set(_vm.data, "list", $$v)},expression:"data.list"}},'draggable',{
                group: 'people',
                ghostClass: 'ghost',
                animation: 200,
                handle: '.drag-widget',
            },false),[_c('transition-group',{staticClass:"widget-form-list st-page",attrs:{"name":"fade","tag":"div"}},[_vm._l((_vm.data.list),function(element,index){return [(element.type == 'grid')?[(element && element.key)?_c('b-row',{key:element.key,staticClass:"widget-col widget-view",class:{ active: _vm.selectWidget.key == element.key},attrs:{"type":"flex","gutter":element.options.gutter ? element.options.gutter : 0,"justify":element.options.justify,"align":element.options.align},on:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_vm._l((element.columns),function(col,colIndex){return _c('b-col',{key:colIndex,attrs:{"cols":col.span ? col.span : 0}},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleWidgetColAdd($event, element, colIndex)}},model:{value:(col.list),callback:function ($$v) {_vm.$set(col, "list", $$v)},expression:"col.list"}},'draggable',{
                                        group: 'people',
                                        ghostClass: 'ghost',
                                        animation: 200,
                                        handle: '.drag-widget',
                                    },false),[_c('transition-group',{staticClass:"widget-col-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((col.list),function(el,i){return [(el.key)?_c('widget-form-item',{key:el.key,attrs:{"element":el,"select":_vm.selectWidget,"index":i,"data":col},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1)],1)}),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-action widget-col-action"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(index)}}})]):_vm._e(),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-drag widget-col-drag"},[_c('i',{staticClass:"fas fa-arrows-alt drag-widget"})]):_vm._e()],2):_vm._e()]:(element.type === 'inline')?[(element && element.key)?_c('div',{key:element.key,staticClass:"widget-inline widget-view",class:{active: _vm.selectWidget.key == element.key},on:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_c('div',{staticClass:"widget-inline-content"},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleWidgetComponentAdd($event, element, 'inline')}},model:{value:(element.list),callback:function ($$v) {_vm.$set(element, "list", $$v)},expression:"element.list"}},'draggable',{
                                        group: 'people',
                                        ghostClass: 'ghost',
                                        animation: 200,
                                        handle: '.drag-widget',
                                    },false),[_c('transition-group',{staticClass:"widget-inline-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((element.list),function(el,elIndex){return [(el && el.key)?_c('widget-form-item',{key:el.key,attrs:{"element":el,"select":_vm.selectWidget,"index":elIndex,"data":element},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-action widget-inline-action"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(index)}}})]):_vm._e(),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-drag widget-inline-drag"},[_c('i',{staticClass:"fas fa-arrows-alt drag-widget"})]):_vm._e()],1)]):_vm._e()]:(element.type === 'section')?[(element && element.key)?_c('div',{key:element.key,staticClass:"widget-view",class:{active: _vm.selectWidget.key == element.key},attrs:{"type":"flex"},on:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_c('st-section',{key:element.key,attrs:{"header":element.options.title,"collapsible":element.options.collapsible,"isCollapsed":element.options.isCollapsed,"collapsibleId":element.key},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleWidgetComponentAdd($event, element, 'section')}},model:{value:(element.list),callback:function ($$v) {_vm.$set(element, "list", $$v)},expression:"element.list"}},'draggable',{
                                            group: 'people',
                                            ghostClass: 'ghost',
                                            animation: 200,
                                            handle: '.drag-widget',
                                        },false),[_c('transition-group',{staticClass:"widget-col-list st-section-body",attrs:{"name":"fade","tag":"div"}},[_vm._l((element.list),function(el,elIndex){return [(el.type == 'grid')?[(el && el.key)?_c('b-row',{key:el.key,staticClass:"widget-col widget-view",class:{active: _vm.selectWidget.key == el.key},attrs:{"type":"flex","gutter":el.options.gutter ? el.options.gutter : 0,"justify":el.options.justify,"align":el.options.align},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectSectionWidget(index, elIndex)}}},[_vm._l((el.columns),function(col,colIndex){return _c('b-col',{key:colIndex,attrs:{"cols":col.span ? col.span : 0}},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleSectionWidgetColAdd($event, element, elIndex, el, colIndex, el.key)}},model:{value:(col.list),callback:function ($$v) {_vm.$set(col, "list", $$v)},expression:"col.list"}},'draggable',{
                                                                    group: 'people',
                                                                    ghostClass: 'ghost',
                                                                    animation: 200,
                                                                    handle: '.drag-widget',
                                                                },false),[_c('transition-group',{staticClass:"widget-col-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((col.list),function(elc,j){return [(elc.key)?_c('widget-form-item',{key:elc.key,attrs:{"element":elc,"select":_vm.selectWidget,"index":j,"data":col},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1)],1)}),(_vm.selectWidget.key == el.key)?_c('div',{staticClass:"widget-view-action widget-col-action"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleSectionWidgetDelete(index, elIndex)}}})]):_vm._e(),(_vm.selectWidget.key == el.key)?_c('div',{staticClass:"widget-view-drag widget-col-drag"},[_c('i',{staticClass:"fas fa-arrows-alt drag-widget"})]):_vm._e()],2):_vm._e()]:(el.type === 'inline')?[(el && el.key)?_c('div',{key:el.key,staticClass:"widget-inline widget-view",class:{active: _vm.selectWidget.key == el.key},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectSectionWidget(index, elIndex)}}},[_c('div',{staticClass:"widget-inline-content"},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleSectionWidgetComponentAdd($event, element, elIndex, el)}},model:{value:(el.list),callback:function ($$v) {_vm.$set(el, "list", $$v)},expression:"el.list"}},'draggable',{
                                                                    group: 'people',
                                                                    ghostClass: 'ghost',
                                                                    animation: 200,
                                                                    handle: '.drag-widget',
                                                                },false),[_c('transition-group',{staticClass:"widget-inline-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((el.list),function(eli,eliIndex){return [(eli && eli.key)?_c('widget-form-item',{key:eli.key,attrs:{"element":eli,"select":_vm.selectWidget,"index":eliIndex,"data":el},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1),(_vm.selectWidget.key == el.key)?_c('div',{staticClass:"widget-view-action widget-inline-action"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleSectionWidgetDelete(index, elIndex)}}})]):_vm._e(),(el.key == el.key)?_c('div',{staticClass:"widget-view-drag widget-inline-drag"},[_c('i',{staticClass:"fas fa-arrows-alt drag-widget"})]):_vm._e()],1)]):_vm._e()]:(el && el.key)?_c('widget-form-item',{key:el.key,attrs:{"element":el,"select":_vm.selectWidget,"index":elIndex,"data":element},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1)]},proxy:true}],null,true)}),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-action widget-col-action"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(index)}}})]):_vm._e(),(_vm.selectWidget.key == element.key)?_c('div',{staticClass:"widget-view-drag widget-col-drag"},[_c('i',{staticClass:"fas fa-arrows-alt drag-widget"})]):_vm._e()],1):_vm._e()]:[(element && element.key)?_c('widget-form-item',{key:element.key,attrs:{"element":element,"select":_vm.selectWidget,"index":index,"data":_vm.data},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }