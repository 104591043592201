import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { VillageField } from '@/shared/common-fields/village-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { CountyField } from '@/shared/common-fields/county-field';
import NumberField from '@/shared/fields/number-field';

const label = (name) => i18n.t(`AC_TAX_VALIDATOR.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`AC_TAX_VALIDATOR.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`AC_TAX_VALIDATOR.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

export const SCOPE_BUILDING = 'locuinte';
export const SCOPE_OTHER = 'other';

export const TYPE_WOOD = 'lemn';
export const TYPE_CONCRETE = 'beton';
export const TYPE_OTHER = 'anexe';


const fields = {
    id: new IdField('id', 'Id'),
    county: new StringField('county', label('county')),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true },
        { placeholder: placeholder('county')},
        [],
        { asyncData: false }
    ),
    territorial_administrative_unit: new StringField('territorial_administrative_unit', label('territorial_administrative_unit')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: false }
    ),
    village_id: VillageField.relationToOne(
        'village_id',
        label('village'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true },
        { placeholder: placeholder('village')},
        [{
            key: 'parent_id',
            name: 'territorial_administrative_unit_id'
        }],
        { asyncData: false },
        { includeMunicipality: true }
    ),
    village: new StringField('village', label('village')),
    street: new StringField('street', label('street'), { required: false }),
    street_number: new StringField('street_number', label('street_number_short'), { required: false }),
    construction_scope: new EnumField(
        'construction_scope',
        label('construction_scope'),
        [
            {
                value: 'locuinte',
                name: 'locuinte',
                text: enumLabel('construction_scope', SCOPE_BUILDING),
                type: 'success',
            },
            {
                value: 'other',
                name: 'other',
                text: enumLabel('construction_scope', SCOPE_OTHER),
                type: 'other',
            },
        ],
        {},
        {
            placeholder: placeholder('construction_scope'),
        },
    ),
    construction_scope_text: new StringField('construction_scope_text', label('construction_scope_text')),
    construction_type: new EnumField(
        'construction_type',
        label('construction_type'),
        [
            {
                value: TYPE_CONCRETE,
                name: TYPE_CONCRETE,
                text: enumLabel('construction_type', TYPE_CONCRETE),
                type: 'primary',
            },
            {
                value: TYPE_WOOD,
                name: TYPE_WOOD,
                text: enumLabel('construction_type', TYPE_WOOD),
                type: 'primary',
            },
            {
                value: TYPE_OTHER,
                name: TYPE_OTHER,
                text: enumLabel('construction_type', TYPE_OTHER),
                type: 'primary',
            },
        ],
        {},
        {
            placeholder: placeholder('construction_type'),
        },
    ),
    construction_type_text: new StringField('construction_type_text', label('construction_type_text')),
    surface: new NumberField('surface', label('surface'), { required: true, scale: 2 }),
    construction_value: new NumberField('construction_value', label('construction_value'), { required: true, scale: 2 }),
    construction_purpose: new StringField('construction_purpose', label('construction_purpose'), { required: true }),
};

export class AcTaxValidatorModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
