import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { CommonService } from '@/shared/services/common.service';

export class CountryField {
    static relationToOne(name, label, rules, filterParams, children) {
        return new RelationToOneField(
            name,
            label,
            CommonService.getCountries,
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.name,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children
        );
    }
}
