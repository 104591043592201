<template>
  <div class="fm-style">
    <b-container fluid class="fm-container">
      <b-row class="h-100">
        <b-col cols="2" class="container-section">
          <div class="components-list">
            <template v-if="basicFields.length">
              <div class="widget-cate">
                {{ $t("FORMS.COMPONENTS.BASIC.TITLE") }}
              </div>
              <draggable
                tag="ul"
                :list="basicComponents"
                v-bind="{
                  group: { name: 'people', pull: 'clone', put: false },
                  sort: false,
                  ghostClass: 'ghost',
                }"
                @end="handleMoveEnd"
                @start="handleMoveStart"
                :move="handleMove"
              >
                <template v-for="(item, index) in basicComponents">
                  <li
                    v-if="basicFields.indexOf(item.type) >= 0"
                    class="form-edit-widget-label"
                    :class="{ 'no-put': item.type == 'divider' }"
                    :key="index"
                  >
                    <a>
                      <i :class="['icon', item.icon]"></i>
                      <span>{{ item.label }}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>
            <template v-if="advanceFields.length">
              <div class="widget-cate">
                {{ $t("FORMS.COMPONENTS.ADVANCE.TITLE") }}
              </div>
              <draggable
                tag="ul"
                :list="advanceComponents"
                v-bind="{
                  group: { name: 'people', pull: 'clone', put: false },
                  sort: false,
                  ghostClass: 'ghost',
                }"
                @end="handleMoveEnd"
                @start="handleMoveStart"
                :move="handleMove"
              >
                <template v-for="(item, index) in advanceComponents">
                  <li
                    v-if="advanceFields.indexOf(item.type) >= 0"
                    class="form-edit-widget-label"
                    :class="{ 'no-put': item.type == 'table' }"
                    :key="index"
                  >
                    <a>
                      <i :class="['icon', item.icon]"></i>
                      <span>{{ item.label }}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>

            <template v-if="layoutFields.length">
              <div class="widget-cate">
                {{ $t("FORMS.COMPONENTS.LAYOUT.TITLE") }}
              </div>
              <draggable
                tag="ul"
                :list="layoutComponents"
                v-bind="{
                  group: { name: 'people', pull: 'clone', put: false },
                  sort: false,
                  ghostClass: 'ghost',
                }"
                @end="handleMoveEnd"
                @start="handleMoveStart"
                :move="handleMove"
              >
                <template v-for="(item, index) in layoutComponents">
                  <li
                    v-if="layoutFields.indexOf(item.type) >= 0"
                    class="form-edit-widget-label no-put"
                    :key="index"
                  >
                    <a>
                      <i :class="['icon', item.icon]"></i>
                      <span>{{ item.label }}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>
            <template v-if="customFields.length">
              <div class="widget-cate">
                {{ $t("FORMS.COMPONENTS.CUSTOM.TITLE") }}
              </div>
              <draggable
                tag="ul"
                :list="customComponents"
                v-bind="{
                  group: { name: 'people', pull: 'clone', put: false },
                  sort: false,
                  ghostClass: 'ghost',
                }"
                @end="handleMoveEnd"
                @start="handleMoveStart"
                :move="handleMove"
              >
                <template v-for="(item, index) in customComponents">
                  <li
                    v-if="customFields.indexOf(item.type) >= 0"
                    class="form-edit-widget-label no-put"
                    :key="index"
                  >
                    <a>
                      <i :class="['icon', item.icon]"></i>
                      <span>{{ item.label }}</span>
                    </a>
                  </li>
                </template>
              </draggable>
            </template>
          </div>
        </b-col>
        <b-col cols="7" class="container-section">
          <b-container class="center-container">
            <header class="btn-bar">
              <slot name="action"></slot>
              <b-button v-if="upload" variant="link" @click="handleUpload">
                <i class="fas fa-upload"></i>
                {{ $t("FORMS.ACTIONS.IMPORT") }}</b-button
              >
              <b-button v-if="clearable" variant="link" @click="handleClear">
                <i class="fas fa-trash-alt"></i>
                {{ $t("FORMS.ACTIONS.CLEAR") }}</b-button
              >
              <b-button v-if="preview" variant="link" @click="handlePreview">
                <i class="fas fa-eye"></i>
                {{ $t("FORMS.ACTIONS.PREVIEW") }}</b-button
              >
              <b-button
                v-if="generateJson"
                variant="link"
                @click="handleGenerateJson"
              >
                <i class="fas fa-clipboard-list"></i>
                {{ $t("FORMS.ACTIONS.JSON") }}</b-button
              >
              <b-button
                v-if="generateCode"
                variant="link"
                @click="handleGenerateCode"
              >
                <i class="fas fa-file"></i>
                {{ $t("FORMS.ACTIONS.CODE") }}</b-button
              >
              <b-button
                variant="link"
                @click="handleSave"
              >
                <i class="fas fa-save"></i>
                {{ $t("GENERAL.BUTTON.SAVE") }}</b-button
              >
            </header>
            <div
              :class="[
                'main-container',
                { 'widget-empty': widgetForm.list.length == 0 },
              ]"
            >
              <widget-form
                v-if="!resetJson"
                ref="widgetForm"
                :data="widgetForm"
                :select.sync="widgetFormSelect"
              ></widget-form>
            </div>
          </b-container>
        </b-col>

        <b-col cols="3" class="">
          <b-container class="widget-config-container">
            <header>
              <div
                class="config-tab"
                :class="{ active: configTab == 'widget' }"
                @click="handleConfigSelect('widget')"
              >
                {{ $t("FORMS.CONFIG.WIDGET.TITLE") }}
              </div>
              <div
                v-if="false"
                class="config-tab"
                :class="{ active: configTab == 'form' }"
                @click="handleConfigSelect('form')"
              >
                {{ $t("FORMS.CONFIG.FORM.TITLE") }}
              </div>
            </header>
            <div class="config-content">
              <widget-config
                v-show="configTab == 'widget'"
                :data="widgetFormSelect"
              ></widget-config>
              <form-config
                v-if="false"
                v-show="configTab == 'form'"
                :data="widgetForm.config"
              ></form-config>
            </div>
          </b-container>
        </b-col>

        <cus-dialog
          :visible="previewVisible"
          @on-close="previewVisible = false"
          ref="widgetPreview"
          width="1000px"
          form
        >
          <generate-form
            insite="true"
            @on-change="handleDataChange"
            v-if="previewVisible"
            :data="widgetForm"
            :value="widgetModels"
            ref="generateForm"
          >
            <template v-slot:blank="scope">
              Width
              <input v-model="scope.model.blank.width" style="width: 100px" />
              Height
              <input v-model="scope.model.blank.height" style="width: 100px" />
            </template>
          </generate-form>

          <template slot="action">
            <b-button variant="primary" @click="handleTest">{{
              $t("FORMS.ACTIONS.GET_DATA")
            }}</b-button>
            <b-button variant="light" @click="handleReset">{{
              $t("FORMS.ACTIONS.RESET")
            }}</b-button>
          </template>
        </cus-dialog>

        <cus-dialog
          :visible="uploadVisible"
          @on-close="uploadVisible = false"
          @on-submit="handleUploadJson"
          ref="uploadJson"
          width="800px"
          form
        >
          <b-form-textarea
            v-model="uploadEditor"
            style="height: 400px; width: 100%"
          >
          </b-form-textarea>
        </cus-dialog>

        <cus-dialog
          :visible="jsonVisible"
          @on-close="jsonVisible = false"
          ref="jsonPreview"
          width="800px"
          form
        >
          <div id="jsoneditor" style="height: 400px; width: 100%">
            {{ jsonTemplate }}
          </div>

          <template slot="action">
            <b-button
              variant="link"
              class="json-btn"
              :data-clipboard-text="jsonCopyValue"
              >{{ $t("FORMS.ACTIONS.COPY_DATA") }}</b-button
            >
          </template>
        </cus-dialog>

        <cus-dialog
          :visible="codeVisible"
          @on-close="codeVisible = false"
          ref="codePreview"
          width="800px"
          form
          :action="false"
        >
          <!-- <div id="codeeditor" style="height: 500px; width: 100%;">{{htmlTemplate}}</div> -->
          <div type="border-card">
            <div label="Vue Component" name="vue">
              <div id="vuecodeeditor" style="height: 500px; width: 100%">
                {{ vueTemplate }}
              </div>
            </div>
            <div label="HTML" name="html">
              <div id="codeeditor" style="height: 500px; width: 100%">
                {{ htmlTemplate }}
              </div>
            </div>
          </div>
        </cus-dialog>
        <forms-create-modal
          v-if="showCreateModal"
          ref="formsCreateModal"
          :schema="widgetForm"
          :fieldsMap="widgetFieldsMetaMap"
          @close="handleCloseCreateModal"
        ></forms-create-modal>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import WidgetConfig from "./WidgetConfig";
import FormConfig from "./FormConfig";
import WidgetForm from "./WidgetForm";
import CusDialog from "./CusDialog";
import GenerateForm from "../generate-form/GenerateForm";
import Clipboard from "clipboard";
import {
  basicComponents,
  layoutComponents,
  advanceComponents,
  customComponents,
} from "./componentsConfig.js";
import generateCode from "./generateCode.js";
import { realEstateExtraFields } from "./realEstateExtraFields.js";
import FormsCreateModal from '../FormsCreateModal.vue';
import { RealEstateModel } from "@/modules/applications/models/real-estate-model";
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { RelatedApplicationModel } from "@/modules/applications/models/related-application-model";
import { ApplicantModel } from "@/modules/applications/models/applicant-model";
import { PersonModel } from "@/modules/control/models/person-model";
import { RealtyModel } from "@/modules/control/models/realty-model";
import { StaffModel } from "@/modules/control/models/staff-model";
import { AcTaxValidatorModel } from "@/modules/applications/models/ac-tax-validator.model";
import { LocalityModel } from "@/modules/applications/models/locality-model";
import { BuildingsModel } from "@/modules/applications/models/buildings-model";
import { UrbanismRegulationsModel } from "@/modules/applications/models/urbanism-regulations-model";

const realEstateFields = {
  cadastral_number: RealEstateModel.fields.cadastral_number,
  county: RealEstateModel.fields.county,
  locality: RealEstateModel.fields.locality,
  land_area: RealEstateModel.fields.land_area,
  ...realEstateExtraFields,
};
const beneficiaryFields = BeneficiaryModel.fields;

const relatedApplicationFields = {
  allocated_document_series: RelatedApplicationModel.fields.allocated_document_series,
  issued_date: RelatedApplicationModel.fields.issued_date,
  issued_by: RelatedApplicationModel.fields.issued_by,
};

const offenderFields = PersonModel.fields;
const witnessFields = PersonModel.fields;
const realtyFields = RealtyModel.fields;
const staffFields = StaffModel.fields;
const applicantFields = ApplicantModel.fields;
const acTaxValidatorFields = AcTaxValidatorModel.fields;
const localityFields = LocalityModel.fields;
const buildingFields = BuildingsModel.fields;
const rluFields = UrbanismRegulationsModel.fields;

export default {
  name: "FmBuildForm",
  components: {
    Draggable,
    WidgetConfig,
    FormConfig,
    WidgetForm,
    CusDialog,
    GenerateForm,
    FormsCreateModal
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    generateCode: {
      type: Boolean,
      default: false,
    },
    generateJson: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    basicFields: {
      type: Array,
      default: () => [
        "input",
        "textarea",
        "number",
        "radio",
        "checkbox",
        "date",
        "select",
        "switch",
        "text",
        "html",
        "divider",
      ],
    },
    advanceFields: {
      type: Array,
      default: () => ["file", "editor"],
    },
    layoutFields: {
      type: Array,
      default: () => ["grid", "section", "inline"],
    },
    customFields: {
      type: Array,
      default: () => [
        "application-detail-form",
        "beneficiary-list",
        "applicant-form",
        "real-estate-list",
        "decision-list",
        "attachments",
        "urbanism-regulation-picker",
        "related-application",
        "offender-list",
        "witness-list",
        "realty-list",
        "staff-form",
        "gis-attachments",
        "ac-tax-validator",
        "locality-list",
        "buildings-list",
        "urbanism-regulations-list",
      ],
    },
  },
  data() {
    return {
      basicComponents,
      layoutComponents,
      advanceComponents,
      customComponents,
      resetJson: false,
      widgetForm: {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: "right",
          size: "small",
        },
      },
      widgetFieldsMetaMap: {},
      configTab: "widget",
      widgetFormSelect: null,
      previewVisible: false,
      jsonVisible: false,
      codeVisible: false,
      uploadVisible: false,

      widgetModels: {},
      blank: "",
      htmlTemplate: "",
      vueTemplate: "",
      jsonTemplate: "",
      uploadEditor: "",
      jsonCopyValue: "",
      jsonClipboard: null,
      showCreateModal: false,
    };
  },
  mounted() {
    this._loadComponents();
    this.setJSON(this.data?.schema || this.widgetForm);
  },
  methods: {
    _loadComponents() {
      this.basicComponents = this.basicComponents.map((item) => {
        return {
          ...item,
          label: this.$t(`FORMS.COMPONENTS.FIELDS.${item.type}`),
        };
      });
      this.advanceComponents = this.advanceComponents.map((item) => {
        return {
          ...item,
          label: this.$t(`FORMS.COMPONENTS.FIELDS.${item.type}`),
        };
      });
      this.layoutComponents = this.layoutComponents.map((item) => {
        return {
          ...item,
          label: this.$t(`FORMS.COMPONENTS.FIELDS.${item.type}`),
        };
      });
      this.customComponents = this.customComponents.map((item) => {
        return {
          ...item,
          label: this.$t(`FORMS.COMPONENTS.FIELDS.${item.type}`),
        };
      });
    },
    handleConfigSelect(value) {
      this.configTab = value;
    },
    handleMoveEnd(evt) {
    },
    handleMoveStart({ oldIndex }) {
    },
    handleMove() {
      return true;
    },
    handlePreview() {
      this.previewVisible = true;
    },
    handleTest() {
      this.$refs.generateForm
        .getData()
        .then((data) => {
          this.$alert({
            type: "info",
            text: JSON.stringify(data, null, 4),
            isTextHtml: false,
            showConfirmButton: false,
          });
          this.$refs.widgetPreview.end();
        })
        .catch((e) => {
          this.$refs.widgetPreview.end();
        });
    },
    handleReset() {
      this.$refs.generateForm.reset();
    },
    async handleSave() {
      await this.getFieldsMap();
      this.showCreateModal = true;
    },
    handleCloseCreateModal() {
      this.showCreateModal = false;
    },
    handleGenerateJson() {
      this.jsonVisible = true;
      this.jsonTemplate = this.widgetForm;

      if (!this.jsonClipboard) {
        this.jsonClipboard = new Clipboard(".json-btn");
        this.jsonClipboard.on("success", (e) => {
          this.$notify({
            icon: 'success',
            message: this.$t("FORMS.MESSAGE.COPY_SUCCESS"),
          });
        });
      }
      this.jsonCopyValue = JSON.stringify(this.widgetForm);
    },
    handleGenerateCode() {
      this.codeVisible = true;
      this.htmlTemplate = generateCode(JSON.stringify(this.widgetForm), "html");
      this.vueTemplate = generateCode(JSON.stringify(this.widgetForm), "vue");
    },
    handleUpload() {
      this.uploadVisible = true;
    },
    handleUploadJson() {
      try {
        this.setJSON(JSON.parse(this.uploadEditor));
        this.uploadVisible = false;
      } catch (e) {
        this.$message.error(e.message);
        this.$refs.uploadJson.end();
      }
    },
    handleClear() {
      this.widgetForm = {
        list: [],
        config: {
          labelWidth: 100,
          labelPosition: "right",
          size: "small",
          customClass: "",
        },
      };

      this.widgetFormSelect = {};
    },
    clear() {
      this.handleClear();
    },
    getJSON() {
      return this.widgetForm;
    },
    getHtml() {
      return generateCode(JSON.stringify(this.widgetForm));
    },
    setJSON(json) {
      this.widgetForm = json;

      if (json.list.length > 0) {
        this.widgetFormSelect = json.list[0];
      }
    },
    handleDataChange(field, value, data) {
    },
    createFieldMap(field, parentField, isRootNode = false) {
      let childParentName;
      if(field.type == 'nestedarray') {
        // child items of a nested array are always accessed by their parent name prefixed by '$',
        // it is the same does not matter on which level the array is
        childParentName = `$${field.name}`;
      } else if(field.type == 'nestedobject') {
        // multilevel object child of an array, first level should be under '$item'
        childParentName = !parentField ? `$item.${field.name}` : `${parentField}.${field.name}`;
      } else {
        childParentName = !parentField ? field.name : `${parentField}.${field.name}`;
      }

      return {
        name: field.name,
        type: field.type || isRootNode ? field.type : 'child',
        child: field.child?.length ? field.child.map(
            childField => this.createFieldMap(
                childField,
                isRootNode && childField.type !== 'property' ? null : childParentName,
            )
        ) : [],
        parentField: field.parentField ?? parentField,
      };
    },
    getFieldsMap() {
      const components = this.widgetForm.list;
      let fields = [];
      for(let i = 0; i < components.length; i++) {
        const component = components[i];
        if (component.type === 'grid') {
          for(let i = 0; i < component.columns.length; i++) {
            const columnComponents = component.columns[i].list;
            fields = [
              ...fields,
              ...columnComponents,
            ];
          }
        } else if (component.type === 'section' || component.type === 'inline') {
          const sectionComponents = component.list;
          for(let i = 0; i < sectionComponents.length; i++) {
            const sectionComponent = sectionComponents[i];
            if (sectionComponent.type === 'grid') {
              for(let j = 0; j < sectionComponent.columns.length; j++) {
                const columnComponents = sectionComponent.columns[j].list;
                fields = [
                  ...fields,
                  ...columnComponents,
                ];
              }
            } else if (sectionComponent.type === 'inline') {
              fields = [
                ...fields,
                ...sectionComponent.list
              ]
            } else {
              fields = [
                ...fields,
                { ...sectionComponent },
              ];
            }
          }
        } else {
          fields.push(component);
        }
      }

      const fieldsMap = fields.map(field => {
        let child = [];
        let fieldObj = {};
        let name = field.name;
        let childType = "child";

        switch (field.type) {
          case 'beneficiary-list':
            name = 'beneficiary_users';
            child = Object.keys(beneficiaryFields);
            break;
          case 'real-estate-list':
            name = 'real_estate_target';
            fieldObj = realEstateFields;
            child = Object.keys(fieldObj);
            break;
          case 'related-application':
            name = `related_application${field.options.docKey}`;
            child = Object.keys(relatedApplicationFields);
            childType = 'property';
            break;
          case 'decision-list':
            name = 'decisions';
            child = [
              'decision_name',
              'description',
              'target_name',
              'purpose_name',
              'extension_name',
            ];
            break;
          case 'inline':
            child = field.list.map((el) => el.name);
            break;
          case 'offender-list':
            name = 'offender';
            child = Object.keys(offenderFields);
            childType = 'property';
            break;
          case 'witness-list':
            name = 'witness';
            child = Object.keys(witnessFields);
            break;
          case 'realty-list':
            name = 'realty';
            child = Object.keys(realtyFields);
            break;
          case 'staff-form':
            name = 'staff';
            child = Object.keys(staffFields);
            childType = 'property';
            break;
          case 'applicant-form':
            name = 'applicant_form';
            child = Object.keys(applicantFields);
            childType = 'property';
            break;
          case 'ac-tax-validator':
            name = 'ac_tax_validator';
            child = Object.keys(acTaxValidatorFields);
            childType = 'property';
            break;
          case 'locality-list':
            name = 'localities';
            child = Object.keys(localityFields);
            break;
          case 'buildings-list':
            name = 'buildings';
            child = Object.keys(buildingFields);
            break;
          case 'urbanism-regulations-list':
            name = 'urbanism_regulations';
            child = Object.keys(rluFields);
            // childType = 'property';
            break;
        }

        const isRoot = true;
        return this.createFieldMap({
          name,
          type: field.type,
          defaultValue: field?.options?.defaultValue ?? '',
          child: child?.map( childName => {
            return {
              name: childName,
              type: fieldObj[childName]?.type ?? childType,
              child: fieldObj[childName]?.child ?? [],
            }
          })
        }, null, isRoot);

      });

      this.widgetFieldsMetaMap = fieldsMap;
    },
  },
  watch: {
    widgetForm: {
      deep: true,
      handler: function (val) {

      },
    },
  },
};
</script>
