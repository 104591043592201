<template>
    <div>
        <st-modal
            id="st-modal-forms"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            :visible="visible"
            size="md"
            @hide="hide"
            :title="$t('FORMS.CREATE_MODAL.TITLE')"
            customClass="form-modal"
        >
            <template #body>
                <form class="form"
                      novalidate="novalidate"
                      id="st_modal_forms_create"
                >
                    <div class="pb-6">
                        <div class="form-group">
                            <label>
                                {{ fields.name.label }} *
                            </label>
                            <st-input-text
                                :ref="fields.name.name"
                                :name="fields.name.name"
                                v-model="model[fields.name.name]"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.description.label }} *
                            </label>
                            <b-form-textarea
                                :ref="fields.description.name"
                                :name="fields.description.name"
                                v-model="model[fields.description.name]"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="hide"
                        > {{ $t('GENERAL.BUTTON.CANCEL') }}
                        </st-button>
                        <st-button
                            size="large"
                            variant="primary"
                            :callback="doSubmit">
                            <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                        </st-button>
                    </div>
                </form>
            </template>
        </st-modal>
    </div>
</template>

<script>

import {FormSchema} from '@/shared/form/form-schema';
import {createFormValidation} from '@/shared/utils/create-form-validation';
import {FormsModel} from '@/modules/forms/forms-model';

const {fields} = FormsModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.description
]);

import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'FormsCreateModal',
    props: {
        schema: {
            type: Object,
            required: true,
        },
        fieldsMap: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            rules: formSchema.rules(),
            model: {},
            fields,
        };
    },
    computed: {
        ...mapGetters({
            record: 'forms/form/record',
        }),
    },
    methods: {
        ...mapActions({
            doCreate: 'forms/form/create',
            doUpdate: 'forms/form/update',
        }),
        hide() {
            this.$emit('close');
            this.doReset();
        },
        doReset() {
            this.model = formSchema.initialValues({});
        },
        open() {
            this.visible = true;
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const {id, ...values} = formSchema.cast(this.model);
                const formData = values;
                formData.schema = this.schema;
                formData.fields_map = this.fieldsMap;
                if (id) {
                    this.doUpdate({id, values: formData}).then(() => {
                        this.hide();
                    });
                } else {
                    this.doCreate(formData).then(() => {
                        this.hide();
                        this.$router.push({ name: 'forms'})
                    });
                }
            }
        }
    },
    async created() {
        this.model = formSchema.initialValues(this.record);
    },
    mounted() {
        // Initialize the form after the modal is shown
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === 'st-modal-forms') {
                this.fv = createFormValidation('st_modal_forms_create', this.rules);
            }
        });
    },
};
</script>

