import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { CountyField } from '@/shared/common-fields/county-field';

const label = (name) => i18n.t(`URBANISM_REGULATIONS_EDIT.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`URBANISM_REGULATIONS_EDIT.PLACEHOLDERS.${name.toUpperCase()}`);
// const enumLabel = (name, value) => i18n.t(`URBANISM_REGULATIONS_EDIT.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    is_existing_utr: new BooleanField('is_existing_utr', label('is_existing_utr'), { required: false }),
    ru_scope: new StringField('ru_scope', label('ru_scope'), { required: true }),
    county: new StringField('county', label('county')),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true },
        { placeholder: placeholder('county')},
        [],
    ),
    territorial_administrative_unit: new StringField('territorial_administrative_unit', label('territorial_administrative_unit')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
    ),
    name: new StringField('name', label('name'), { required: true }),
    symbol: new StringField('symbol', label('symbol'), { required: true }),
    // section 1  - characteristics
    // Articolul 1: Condiționări generale
    article_1: new StringField('article_1', label('article_1'), { required: true }),
    // Articolul 2: Servituți
    article_2: new StringField('article_2', label('article_2'), { required: true }),
    // section 2 - function
    // Articolul 3: Utilizări permise
    article_3: new StringField('article_3', label('article_3'), { required: true }),
    // Articolul 4: Utilizări permise cu condiționări
    article_4: new StringField('article_4', label('article_4'), { required: true }),
    // Articolul 5: Utilizări interzise
    article_5: new StringField('article_5', label('article_5'), { required: true }),
    // section 3 - conditions
    // Articolul 6: Caracteristici ale parcelelor
    article_6: new StringField('article_6', label('article_6'), { required: true }),
    // Articolul 7: Amplasarea clădirilor față de aliniament
    article_7: new StringField('article_7', label('article_7'), { required: true }),
    // Articolul 8: Amplasarea clădirilor față de limitele laterale și posterioare ale parcelelor
    article_8: new StringField('article_8', label('article_8'), { required: true }),
    // Articolul 9: Amplasarea clădirilor unele față de altele pe aceeași parcelă
    article_9: new StringField('article_9', label('article_9'), { required: true }),
    // Articolul 10: Condiții de acces și circulații
    article_10: new StringField('article_10', label('article_10'), { required: true }),
    // Articolul 11: Staționarea autovehiculelor
    article_11: new StringField('article_11', label('article_11'), { required: true }),
    // Articolul 12: Înălțimea maximă a clădirilor
    article_12: new StringField('article_12', label('article_12'), { required: true }),
    // Articolul 13: Aspectul exterior al clădirilor
    article_13: new StringField('article_13', label('article_13'), { required: true }),
    // Articolul 14: Condiții de echipare edilitară
    article_14: new StringField('article_14', label('article_14'), { required: true }),
    // Articolul 15: Spații verzi
    article_15: new StringField('article_15', label('article_15'), { required: true }),
    // Articolul 16: Împrejmuiri
    article_16: new StringField('article_16', label('article_16'), { required: true }),
    // section 4 - usage
    // Articolul 17: Procentul maxim de ocupare al terenului (POT)
    article_17: new StringField('article_17', label('article_17'), { required: true }),
    // Articolul 18: Coeficientul maxim de utilizare a terenului (CUT)
    article_18: new StringField('article_18', label('article_18'), { required: true }),
};

export class UrbanismRegulationsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
