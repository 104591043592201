import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { CommonService } from '@/shared/services/common.service';

export class InstitutionsField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            CommonService.territorialAdministrativeUnitsWithCountryListAutocomplete,
            (record) => {
                if (!record) {
                    return '';
                }

                let textValue = '';
                if (record.id === '127') {
                    textValue = 'Consiliul Judetean Cluj'
                } else {
                    textValue = `Primaria ${record.name}`;
                }

                return {
                    value: textValue,
                    text: textValue,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
