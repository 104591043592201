export const realEstateExtraFields = {
    immovable_id: {
        name: 'immovable_id',
        // type: 'child',  // default type is "child", no need to specify
    },
    county_id: {
        name: 'county_id',
    },
    parent_immovable_id: {
        name: 'parent_immovable_id',
    },
    parent_immovable_type_code: {
        name: 'parent_immovable_type_code',
    },
    immovable_type_code: {
        name: 'immovable_type_code',
    },
    electronic_identifier: {
        name: 'electronic_identifier',
    },
    previous_cad_no: {
        name: 'previous_cad_no',
    },
    topographic_no: {
        name: 'topographic_no',
    },
    previous_land_book_no: {
        name: 'previous_land_book_no',
    },
    measuread_area: {
        name: 'measuread_area',
    },
    legal_area: {
        name: 'legal_area',
    },
    land_book_type_code: {
        name: 'land_book_type_code',
    },
    buildings: {
        name: 'buildings',
        type: 'nestedarray',
        child: [
            { name: 'immovable_id',},
            { name: 'country_id' },
            { name: 'parent_id' },
            { name: 'electronic_identifier' },
            { name: 'previous_cad_no' },
            { name: 'topographic_no' },
            { name: 'previous_land_book_no' },
            { name: 'measuread_area' },
            { name: 'legal_area' },
            { name: 'constructed_area' },
            { name: 'legal' },
            {
                name: 'current_use',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
        ]
    },
    owners: {
        name: 'owners',
        type: 'nestedarray',
        child: [
            { name: 'registration_id' },
            { name: 'county_id' },
            { name: 'person_name' },
            { name: 'person_first_name'},
            { name: 'national_code' },
            { name: 'actual_quota' },
            { name: 'is_part_holder' },
            { name: 'is_active' },
            {
                name: 'ownership_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'registration_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'right_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'property_transfer_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            }
        ]
    },
    parcels: {
        name: 'parcels',
        type: 'nestedarray',
        child: [
            { name: 'immovable_id' },
            { name: 'country_id' },
            { name: 'electronic_identifier' },
            { name: 'land_plot_no' },
            { name: 'parcel_no' },
            { name: 'property_title_no' },
            { name: 'measuread_area' },
            { name: 'legal_area' },
            { name: 'is_agricultural' },
            { name: 'intravilan' },
            {
                name: 'use_category',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
        ]
    },
    postal_address: {
        name: 'postal_address',
        type: 'nestedobject',
        child: [
            { name: 'street_name', },
            { name: 'postal_number', },
            { name: 'building_number', },
            { name: 'building_part_number', },
            { name: 'floor_number', },
            { name: 'building_unit_number', },
            { name: 'postal_code', },
            { name: 'details', },
            {
                name: 'county',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'admin_unit',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'locality',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'street_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
        ]
    },
    registrations: {
        name: 'registrations',
        type: 'nestedarray',
        child: [
            { name: 'registration_id' },
            { name: 'status' },
            { name: 'application_no' },
            { name: 'application_date' },
            { name: 'landbook_section' },
            { name: 'landbook_section_position' },
            { name: 'right_comment' },
            { name: 'initial_quota' },
            { name: 'actual_quota' },
            { name: 'currency_code' },
            { name: 'currency_amount' },
            { name: 'note' },
            { name: 'strike_out_app_no' },
            { name: 'strike_out_app_date' },
            {
                name: 'registration_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'right_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'property_transfer_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'name', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'quota_type',
                type: 'skippedobject',
                child: [
                    { name: 'code', },
                    { name: 'catalogueCode', }
                ]
            },
            {
                name: 'immovables_list',
                type: 'nestedarray',
                child: [
                    { name: 'immovable_id' },
                    { name: 'county_id' },
                    { name: 'electronic_identifier' },
                    { name: 'immovable_component_type_code' }
                ]
            },
            {
                name: 'deeds_list',
                type: 'nestedarray',
                child: [
                    { name: 'issue_number' },
                    { name: 'issue_date' },
                    { name: 'issuer' },
                    { name: 'comment' },
                    { name: 'file_locator' },
                    {
                        name: 'type',
                        type: 'skippedobject',
                        child: [
                            { name: 'code', },
                            { name: 'name', },
                            { name: 'catalogueCode', }
                        ]
                    },
                ]
            },
            {
                name: 'registration_persons_list',
                type: 'nestedarray',
                child: [
                    { name: 'nationality_code' },
                    { name: 'person_name' },
                    { name: 'person_first_name' },
                    { name: 'romanian_id_no' },
                    { name: 'other_id_document_type_code' },
                    { name: 'id_document_no' },
                    { name: 'registration_person_status' },
                    { name: 'registration_person_comment' },
                    {
                        name: 'person_type',
                        type: 'skippedobject',
                        child: [
                            { name: 'code', },
                            { name: 'catalogueCode', }
                        ]
                    },
                ]
            },
        ],
    },
}
