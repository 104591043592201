import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import { idCard } from '@/shared/utils/id-card';

const label = (name) => i18n.t(`STAFF.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`STAFF.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    first_name: new StringField('first_name', label('first_name'), { required: true }),
    last_name: new StringField('last_name', label('last_name'), { required: true }),
    address: new StringField('address', label('address'), { required: true }),
    identification_number: new StringField('identification_number', label('identification_number'), { required: true }),
    id_released_by: new StringField('id_released_by', label('id_released_by'), { required: true }),
    function: new StringField('function', label('function'), {}),
    organisation: new StringField('organisation_name', label('organisation'), {}),
    organisation_id: new StringField('organisation_id', '', {}),
    id_expiration_date: new StringField(
        'id_expiration_date',
        label('id_expiration_date'),
        {
            minDate: idCard.expirationDate,
            required: true
        },
    ),
};

export class StaffModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
