import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class BeneficiaryField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise(async (resolve) => {
                    ApiService.query('/beneficiaries', {
                        params: { limit: 100}
                    }).then(({ data }) => {
                        resolve(data.data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: `${record.first_name} ${record.last_name}`,
                    user_type_name: record.user_type_name
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
