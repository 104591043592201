import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import NumberField from '@/shared/fields/number-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`BUILDINGS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`BUILDINGS.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`BUILDINGS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), { required: true }),
    height: new NumberField('height', label('height'), { required: true }),
    max_height: new NumberField('max_height', label('max_height'), { required: true }),
    existing_levels: new StringField('existing_levels', label('existing_levels'),{ required: true }),
    proposed_levels: new StringField('proposed_levels', label('proposed_levels'),{ required: true }),
    construction_type: new StringField('construction_type', label('construction_type'),{ required: true }),
    foundations: new StringField('foundations', label('foundations'),{ required: true }),
    roof_type: new StringField('roof_type', label('roof_type'),{ required: true }),
    heating_type: new StringField('heating_type', label('heating_type'),{ required: true }),
    isolation: new StringField('isolation', label('isolation'),{ required: true },{ placeholder: placeholder('isolation')}),
    exterior_finish: new StringField('exterior_finish', label('exterior_finish'),{ required: true },{ placeholder: placeholder('exterior_finish')}),
    exterior_woodwork: new StringField('exterior_woodwork', label('exterior_woodwork'),{ required: true },{ placeholder: placeholder('exterior_woodwork')}),
    function: new EnumField(
        'function',
        label('function'),
        [
            {
                value: 'cl',
                name: 'cl',
                text: enumLabel('function', 'cl'),
                type: 'primary',
            },
            {
                value: 'cas',
                name: 'cas',
                text: enumLabel('function', 'cas'),
                type: 'primary',
            },
            {
                value: 'cie',
                name: 'cie',
                text: enumLabel('function', 'cie'),
                type: 'primary',
            },
            {
                value: 'ca',
                name: 'ca',
                text: enumLabel('function', 'ca'),
                type: 'primary',
            }
        ],
        { required: true },
    ),
    category_of_importance:  new EnumField(
        'category_of_importance',
        label('category_of_importance'),
        [
            {
                value: 'A',
                name: 'A',
                text: enumLabel('category_of_importance', 'a'),
                type: 'primary',
            },
            {
                value: 'B',
                name: 'B',
                text: enumLabel('category_of_importance', 'b'),
                type: 'primary',
            },
            {
                value: 'C',
                name: 'C',
                text: enumLabel('category_of_importance', 'c'),
                type: 'primary',
            },
            {
                value: 'D',
                name: 'D',
                text: enumLabel('category_of_importance', 'd'),
                type: 'primary',
            }
        ],
        { autocompleteRequired: true  },
    ),
    building_function: new EnumField(
        'building_function',
        label('building_function'),
        [
            { value: 'existing', text: enumLabel('building_function', 'existing'), type: 'primary', name: 'existing' },
            { value: 'proposed', text: enumLabel('building_function', 'proposed'), type: 'primary' , name: 'proposed'},
        ],
        { required: true },
        { placeholder: placeholder('building_function')},
    ),
    building_func_existing: new EnumField(
        'building_func_existing',
        label('building_func_existing'),
        [
            { value: 'dissolved', text: enumLabel('building_func_existing', 'dissolved'), type: 'primary', name: 'dissolved' },
            { value: 'maintained', text: enumLabel('building_func_existing', 'maintained'), type: 'primary' , name: 'maintained'},
        ],
        { required: true },
        { placeholder: placeholder('building_func_existing')},
    ),
    built_area: new NumberField('built_area', label('built_area'),{ required: true }),
    developed_area: new NumberField('developed_area', label('developed_area'),{ required: true }),
    useful_area: new NumberField('useful_area', label('useful_area'),{ required: true }),
    living_area: new NumberField('living_area', label('living_area'),{ required: true }),
    proposed_apartments_no: new StringField('proposed_apartments_no', label('proposed_apartments_no'),{ placeholder: placeholder('proposed_apartments_no')}),
    one_room_apartment_no: new NumberField('one_room_apartment_no', label('one_room_apartment_no'),{ placeholder: placeholder('one_room_apartment_no')}),
    two_rooms_apartment_no: new NumberField('two_rooms_apartment_no', label('two_rooms_apartment_no'),{ placeholder: placeholder('two_rooms_apartment_no')}),
    three_rooms_apartment_no: new NumberField('three_rooms_apartment_no', label('three_rooms_apartment_no'),{ placeholder: placeholder('three_rooms_apartment_no')}),
    four_rooms_apartment_no: new NumberField('four_rooms_apartment_no', label('four_rooms_apartment_no'),{ placeholder: placeholder('four_rooms_apartment_no')}),
    five_rooms_apartment_no: new NumberField('five_rooms_apartment_no', label('five_rooms_apartment_no'),{ placeholder: placeholder('five_rooms_apartment_no')}),
    other_rooms_apartment_no: new NumberField('other_rooms_apartment_no', label('other_rooms_apartment_no'),{ placeholder: placeholder('other_rooms_apartment_no')}),
    other_characteristics: new StringField('other_characteristics', label('other_characteristics'),{ required: true }),
    cl_housing_types: new EnumField(
        'cl_housing_types',
        label('cl_housing_types'),
        [
            {
                value: 'permanent',
                name: 'permanent',
                text: enumLabel('cl_housing_types', 'permanent'),
                type: 'primary',
            },
            {
                value: 'seasonal',
                name: 'seasonal',
                text: enumLabel('cl_housing_types', 'seasonal'),
                type: 'primary',
            },
            {
                value: 'sociable',
                name: 'sociable',
                text: enumLabel('cl_housing_types', 'sociable'),
                type: 'primary',
            },
            {
                value: 'for_rent',
                name: 'for_rent',
                text: enumLabel('cl_housing_types', 'for_rent'),
                type: 'primary',
            },
            {
                value: 'of_duty',
                name: 'of_duty',
                text: enumLabel('cl_housing_types', 'of_duty'),
                type: 'secondary',
            },
            {
                value: 'of_necessity',
                name: 'of_necessity',
                text: enumLabel('cl_housing_types', 'of_necessity'),
                type: 'secondary',
            },
            {
                value: 'of_intervention',
                name: 'of_intervention',
                text: enumLabel('cl_housing_types', 'of_intervention'),
                type: 'secondary',
            },
            {
                value: 'of_protocol',
                name: 'of_protocol',
                text: enumLabel('cl_housing_types', 'of_protocol'),
                type: 'secondary',
            },
        ],
    ),
};

export class BuildingsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
