<template>
    <div class="h-100">
        <loader v-if="isLoading"/>
        <form-builder
            v-else
            ref="formBuilder" 
            upload preview 
            generate-json
            :data="record"
            clearable>
            <template slot="action">
            </template>
        </form-builder>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FormBuilder from '../components/form-builder/FormBuilder.vue';

export default {
    name: 'FormBuilderForm',
    components: {
        FormBuilder,
    },
    props: {
        id: String,
    },
    computed: {
        ...mapGetters({
            record: 'forms/form/record',
            loading: 'shared/loading',
        }),
        loadingFind() {
            return this.loading['forms/find'];
        },
        isLoading() {
            return this.loadingFind;
        },
    },
    methods: {
        ...mapActions({
            doFind: 'forms/form/find',
            doClear: 'forms/form/clear',
        }),
    },
    async created() {
        if (this.id) {
            await this.doFind(this.id);        
        } else {
            await this.doClear();
        }
    },
}
</script>